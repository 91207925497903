var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.$apollo.loading,"spinner-variant":"primary"}},[_c('section',{staticClass:"grid-view"},_vm._l((_vm.Reviews),function(Review,i){return _c('b-card',{key:i,staticClass:"ecommerce-card",attrs:{"title":Review.title,"no-body":""}},[_c('b-card-body',[_c('b-card-title',[_vm._v("Review title: "+_vm._s(Review.title))]),_c('b-card-sub-title',[_vm._v("Review type: "+_vm._s(Review.type))])],1),_c('b-card-body',[_c('b-card-text',[_vm._v("Operation title: "+_vm._s(Review.programs_operationstable.title))]),_c('b-card-sub-text',[_c('br'),_vm._v("Operation type: "+_vm._s(Review.programs_operationstable.operations_type))])],1),_c('div',{staticClass:"item-options text-center"},[(Review.review_status==='disabled')?_c('b-link',{staticClass:"btn btn-success btn-cart",attrs:{"to":{
            name: 'startup-review-evaluations',
            params: {
              pid: _vm.$route.params.id,
              apid: Review.id,
              aid: _vm.$route.params.aid,
            },
          }}},[_c('span',[_vm._v("Report")])]):_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.selectStartup",modifiers:{"selectStartup":true}}],staticClass:"btn btn-wishlist btn-danger",attrs:{"variant":"primary"}},[_c('span',[_vm._v("Report")])]),_c('b-link',{staticClass:"btn btn-primary btn-cart",attrs:{"to":{
            name: 'startup-review-questions',
            params: {
              pid: _vm.$route.params.id,
              apid: Review.id,
              aid: _vm.$route.params.aid,
            },
          }}},[_c('span',[_vm._v("View / Submit")])])],1)],1)}),1),_c('b-modal',{attrs:{"id":"selectStartup","ok-only":"","ok-title":"OK","size":"lg","title":"Report"}},[_c('p',{staticClass:"my-1"},[_vm._v(" No report available ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }